import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import Dropdown from '../../../components/Dropdown/Dropdown';
import ImplantationSingleCard from '../../../components/ImplantationSingleCard/ImplantationSingleCard';
import PageBannerMap from '../../../components/PageBannerMap/PageBannerMap';
import Seo from '../../../components/Seo/Seo';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import useMedias from '../../../hooks/useMedias';
import Layout from '../../../Layout';
import GraphRH from '../../../Molecules/GraphRH/GraphRH';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { getBreadCrumb } from '../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const ImplantationPage = ({ data }) => {
	const intl = useIntl();
	const allSiegeData = data?.allSiegeData.edges;
	const implantationData = data?.implantationData.edges[0].node;
	const imageArray = data.allImages.edges;
	const allMenu = data.allMenu.edges;
	const breadCrumb = getBreadCrumb(allMenu, implantationData.path?.alias);

	let siegeListArr = [];
	let dropDownArr = [];
	let counter = 1;
	const { getImage, processUrl } = useMedias();

	const [siegeList, setSiegeList] = useState([]);
	const [dropDownList, setDropDownList] = useState([]);
	const [selectedContinent, setSelectedContinent] = useState('');
        const metaTags =  implantationData.metatag;
        let metaTitle = '';
        let metaDesc = '';

        metaTags.forEach((meta) => {
          if(meta.attributes.name === 'title'){
              metaTitle = meta.attributes.content;
          }
          if(meta.attributes.name === 'description'){
              metaDesc = meta.attributes.content;
          }
        });

	const addSiege = (siege) => {
		let continentAvail = siegeListArr.filter(
			(a) => a.continent === siege.node?.field_continent
		);
		let nodeObj = siege.node;
		Object.assign(nodeObj, { visuel: data.file?.publicURL });

		if (continentAvail.length > 0) {
			siegeListArr
				.filter((a) => a.continent === nodeObj?.field_continent)[0]
				?.others.push(nodeObj);
		} else {
			const continent = intl.formatMessage({
				id: `implantation.continent.${nodeObj?.field_continent}`,
			});
			siegeListArr.push({
				continent: nodeObj?.field_continent,
				continentTrad: continent,
				others: [nodeObj],
			});
		}
		siegeListArr.sort((a, b) => a.continent.localeCompare(b.continent));
		setSiegeList(siegeListArr);
	};

	useEffect(() => {
		allSiegeData.forEach((siege) => {
			addSiege(siege);
		});

		dropDownArr.push({
			id: 0,
			label: intl.formatMessage({ id: 'implantation.select_continent' }),
		});

		siegeListArr.forEach(
			(siege) =>
				siege.continent != null &&
				dropDownArr.push({
					id: counter++,
					label: siege.continentTrad,
				})
		);

		setDropDownList(dropDownArr);
	}, []);

	const onChangeContinentDropdown = (continent_selected) => {
		setSelectedContinent(continent_selected);
	};

	const slider_setting_cards = {
		className: 'implantation_cards',
		centerMode: true,
		infinite: false,
		centerPadding: '20px',
		speed: 300,
		initialSlide: 0,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<Layout>
			<Seo

				title={implantationData?.field_metatag?.title}
				description={implantationData?.field_metatag?.description}
			/>
			<div className={classNames('page_template', 'implantations_page')}>
				<PageBannerMap
					//iframeURL='https://colas-implantations-monde.latitude-cartagene.com/'
					imageUrl={processUrl(getImage(imageArray, implantationData.relationships?.field_image?.drupal_internal__mid).uri?.url)}
					breadcrumbData={{
						parentPage: {
							title: breadCrumb?.parent?.name,
							url: breadCrumb?.parent?.link,
						},
						currentPage: {
							title: breadCrumb?.current?.name,
							url: breadCrumb?.current?.link,
						},
						locale: implantationData.langcode,
					}}
				>
					<div className='wrapper_page'>
						<TitlePage color='color_white' title={implantationData.title} />
						{implantationData.relationships?.field_blocs?.map((block, i) => {
							if (block.__typename === 'block_content__block_chiffres_clefs') {
								return (
									<div key={i}>
										<div className='col_2'>
											<div className='col graph'>
												<GraphRH
													text={
														block.relationships?.field_values[0]?.field_legend
															?.processed
													}
													jobs={
														block.relationships?.field_values[0]?.field_chiffre
													}
													textColor='color_white'
												/>
											</div>
											<div
												className='col text'
												dangerouslySetInnerHTML={{
													__html: block?.body?.processed,
												}}
											></div>
										</div>
									</div>
								);
							}
						})}
					</div>
				</PageBannerMap>
				<section
					className={classNames('section_content', 'section_region_list')}
				>
					<div className='dropdown_container wrapper_page'>
						<Dropdown
							onChange={onChangeContinentDropdown}
							selected={selectedContinent}
							list={dropDownList}
						/>
					</div>
					{siegeList.map((siege, i) => (
						<div
							key={i}
							className={classNames(
								'continent_data',
								selectedContinent?.id === 0 || selectedContinent === ''
									? 'show'
									: selectedContinent?.label === siege.continentTrad
										? 'show'
										: 'hide'
							)}
							aria-live='polite'
						>
							<div className='wrapper_page'>
								<TitleSection
									title={siege.continentTrad}
									description={
										siege.others?.length +
										' ' +
										intl.formatMessage({
											id: `implantation.nb_locations.${siege.others?.length > 1 ? 'plural' : 'singular'
												}`,
										})
									}
									type='line'
								/>
								<div className='listCardsDesktop'>
									{siege.others?.map((card, index) => (
										<ImplantationSingleCard
											key={index}
											title={card?.title}
											adresse={card?.field_address || null}
											tel1={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[0]
													: card?.field_telephone
											}
											tel2={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[1]
													: null
											}
											email={card.field_email}
										/>
									))}
								</div>
							</div>
							<div className='listCardsMobile'>
								<SliderComponent settings={slider_setting_cards}>
									{siege.others?.map((card, index) => (
										<ImplantationSingleCard
											key={index}
											title={card.title}
											adresse={card?.field_address || null}
											tel1={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[0]
													: card?.field_telephone
											}
											tel2={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[1]
													: null
											}
											email={card.field_email}
										/>
									))}
								</SliderComponent>
							</div>
						</div>
					))}
				</section>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query implatationTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!]) {
		implantationData: allNodePage(
			filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
		) {
			edges {
				node {
					title
					path {
						alias
						langcode
					}
					field_metatag {
						description
						title
					}
                                        metatag {
                                                attributes {
                                                        content
                                                        name
                                                }
                                        }
					field_type
					langcode
					body {
						processed
					}
					relationships {
						field_image {
							drupal_internal__mid
						  }
						field_blocs {
							__typename
							... on Node {
								... on block_content__block_chiffres_clefs {
									id
									body {
										processed
									}
									field_title {
										processed
									}
									relationships {
										field_values {
											field_chiffre
											field_legend {
												processed
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		allSiteData: allNodeNosImplantations(
			filter: { langcode: { eq: $locale } }
		) {
			edges {
				node {
					field_address
					field_city
					field_code_postale
					field_email
					field_telephone
					field_type_etablissement
					field_continent
					title
					path {
						alias
						langcode
					}
					langcode
					drupal_id
				}
			}
		}

		allSiegeData: allNodeNosImplantations(
			filter: {
				langcode: { eq: $locale }
				field_type_etablissement: { eq: "Siège" }
			}
			sort: {order: ASC, fields: title}
		) {
			edges {
				node {
					field_address
					field_city
					field_code_postale
					field_email
					field_telephone
					field_type_etablissement
					field_continent
					title
					path {
						alias
						langcode
					}
					langcode
					drupal_id
				}
			}
		}
		allImages: allMediaImage(
			filter: {
			  langcode: { eq: "fr" }
			  drupal_internal__mid: { in: $imagesID }
			}
		  ) {
			edges {
			  node {
				drupal_internal__mid
				relationships {
				  field_media_image {
					uri {
					  url
					}
				  }
				}
			  }
			}
		  }
		  allMenu: allMenuLinkContentMenuLinkContent(
			filter: { langcode: { eq: $locale } }
		  ) {
			edges {
			  node {
				drupal_parent_menu_item
				drupal_id
				langcode
				link {
				  url
				}
				menu_name
				title
			  }
			}
		  }
	}
`;

export default ImplantationPage;
