import React, { useState, useEffect } from 'react';

import BGArrowPointerBottomMobile from '../../assets/images/bg-page-banner-v2-bottom-mobile.inline.svg';
import BGArrowPointerBottomLeft from '../../assets/images/bg-page-banner-v2-bottom.inline.svg';
import BGArrowPointerTopMobile from '../../assets/images/bg-page-banner-v2-top-mobile.inline.svg';
import BGArrowPointerTopLeft from '../../assets/images/bg-page-banner-v2-top.inline.svg';
import { ShapeYellowArrowBanner, IconDeploySelector } from '../../Atoms/Icons/Icons';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import './PageBannerMap.scss';
let classNames = require('classnames');

const PageBannerMap = ({ breadcrumbData, iframeURL, imageUrl, children }) => {

  const onClickScrollIcon = (e) => {
    e.preventDefault();
    //console.log('on click arrow');
  }
  return (
    <div className="page_banner_map">
      <div className={classNames('visuel_container')}>
        <div className="visuel">
          {
            iframeURL ? (
              <iframe id="mapiframe" src={iframeURL} width="100%" height="100%" />
            ) : <img src={imageUrl} />
          }

        </div>
        <div className={classNames('visuel_overlay')}> {/* 'hide': showMap */}
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={breadcrumbData}
            />
          </div>
        </div>
      </div>
      <div className={classNames('intro_design')}>
        <div className={classNames('shaped_background_banner')}>
          <div className={classNames('bg_arrow_pointer_top')}>
            {/* <BGArrowPointerTopMobile className="only_mobile_tablet" />
            <BGArrowPointerTopLeft className="only_desktop" /> */}
          </div>
          <a href="#" className={classNames('btn_chevron')}>
            <IconDeploySelector aria-hidden="true" className="chevron" onClick={(e) => onClickScrollIcon(e)} />
          </a>
          <div className="center_content">
            {children}
          </div>
          <div className={classNames('bg_arrow_pointer_bottom')}>
            <BGArrowPointerBottomMobile className="only_mobile_tablet" />
            <BGArrowPointerBottomLeft className="only_desktop" />
          </div>
        </div>
        <ShapeYellowArrowBanner className="shape_yellow_banner_bottom" />
      </div>
    </div>
  );
};

export default PageBannerMap;
